<template>
    <div class="mb-5">
        <div class="__main_card_container">
            <div class="main_card_body">
                <div class="row">
                    <div class="col-md-12" v-if="loading">
                        <div class="row mt-5 pt-5">
                            <div class="col-md-12" v-loading="loading">

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 my-4" v-if="!loading">
                        <div class="d-flex justify-content-end">
                            <div class="form_contain">
                                <el-input
                                    placeholder="Search Sender ID"
                                    class="search_item"
                                    style="height: 40px!important;"
                                    v-model="search" 
                                    clearable
                                    >
                                    <i slot="prefix" style="padding-to:2em;" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="!loading">
                        <el-table
                            :data="displayData"
                            :header-cell-style="{ background:'rgba(212, 216, 226, 0.2)'}"
                            style="width: 100%"
                            :empty-text="'No Data Available'"
                            height="55vh"
                            >
                            <el-table-column
                                label="User Name"
                                
                                >
                                <template slot-scope ="scope">
                                    <p>
                                        {{ (scope.row.user.name)}}
                                    </p>

                                 </template>
                            </el-table-column>
                            <el-table-column
                                label="Phone Number"
                                prop="phone"
                                >
                                <template slot-scope ="scope">
                                    <p>
                                        {{ (scope.row.user.phone)}}
                                    </p>

                                 </template>
                            </el-table-column>
                            <el-table-column
                                label=" Sender ID"
                                prop="sender_id"
                                >
                            </el-table-column>
                            <el-table-column
                                label="Reserved By"
                             >
                                <template slot-scope="scope">
                                    <p> 
                                        {{ (scope.row.admin.name) }}
                                    </p>
                                </template>
                                
                            </el-table-column>
                            <el-table-column
                                label="Created On"
                                >
                                <template slot-scope="scope">
                                    <p> 
                                        {{ moment(scope.row.end_date).format('ll') }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Action"
                                width="200">
                                <template slot-scope="scope">
                                    <button class="text-danger actionBtn" @click="deleteSenderId(scope)">
                                        Delete
                                    </button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div> 
                    <div class="col-md-12" style="text-align: center; margin-top:2em;">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
                </div>
            </div>
            <!-- CREATE RESERVED SENDER ID MODAL -->
            <div class="modal fade" id="ReservedSenderIDs" tabindex="-1" role="dialog" aria-labelledby="contact" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered " role="document">
                    <div class="modal-content d-flex align-items-center" v-if="success">
                        <successMessage :message="success" />
                        <button @click="hideModal" class="__close__success__modal">Close</button>
                    </div>
                    <div class="modal-content pb-3" v-if="!success">
                        <div class="modal-header pt-4 pb-2">
                            <h5 class="modal-title " id="exampleModalLabel">
                            {{payload.header}}
                            </h5>
                        </div>
                        <div class="modal-body m-0" >       
                            <div class="row justify-content-center">
                                <div class="col-md-12 mb-5">
                                        <el-form :model="formData" :rules="rules" ref="ruleForm"  @submit.prevent.native="checkEnter">
                                            <div class="row justify-content-center">
                                                <div class="col-md-12"> 
                                                    <div class="form_input_container">
                                                        <el-form-item label="Reserved Sender Ids" prop="reservedIDs">
                                                        <el-input type="text"  autocomplete="off" placeholder="Enter Sender Id"  v-model.number="formData.reservedIDs"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                            </div>
                                    </el-form>
                                </div>
                            </div>
                            <div class="row justify-content-center mb-4">
                                <div  class="col-md-12 modal__footer__btns d-flex justify-content-between">
                                    <button  style="width:123px" class="cancel" @click="hideModal">Cancel</button>
                                    <el-button  type="primary" :loading="loadingAction"
                                        id="cancelinput" @click="submitAction('ruleForm')">
                                        {{ payload.header == 'Edit Sender Id' ? 'Edit' : 'Create'}} Reserved Sender Id
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END CREATE AND EDIT RESVERED SENDER IDs MODAL -->
            <ActionModal ref="actionModal" :payload="payload"/>
        </div>
    </div>
</template>
<script>
import SenderIDTable from './senderidTable.vue'
import moment from 'moment'
import $ from 'jquery'
import store from '@/state/store.js'
import appConfig from '@/app.config'

export default {
    page: {
		title: 'Reserved Sender Ids',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    components: {
          SenderIDTable
      },
    name:'admin-ReservedSenderIDs',
    data() {
        return {
            loading: false,
            payload: {
                type: '',
                header: '',
                data: {}
            },
            success: '',
            error: '',
            loadingAction: false,
            formData: {
                reservedIDs:''
            },
            rules:{
                reservedIDs:[
                    { required: true, message: 'Please Sender ID is required.', trigger: 'change' },
                ],
            },
            page: 1,
            pageSize:20,
            total: 0,
            search:'',
            reservedIDs:[]

        }
    },
    methods: {
        handleCurrentChange(val){
            this.page = val;
        },
        submitAction(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loadingAction = true
                    this.success = ''
                    if(this.payload.header == 'Edit Sender IDs') {
                        let { row, $index } = this.payload.data
                        return store.dispatch('admin/ReservedSenderIDs/editSenderIDs', {
                            reservedIDs: this.formData.reservedIDs,
                            industry_id: row.id,
                            key: $index
                        })
                        .then( res => {
                                this.loadingAction = false
                                this.success = res
                        }).catch(err => {
                            this.loadingAction = false
                            if(err?.response?.data == undefined) {
                                this.errors = "Network error try again."
                            }
                        })
                    } else {
                        let payload ={}
                        return store.dispatch('admin/ReservedSenderIDs/createSenderIDs', this.formData)
                        .then( message => {
                                this.loadingAction = false
                                this.success = message
                        }).catch(err => {
                            this.loadingAction = false
                            if(err?.response?.data == undefined) {
                                this.errors = "Network error try again."
                            }
                        })
                    }

                }else {
                    this.loadingAction = false;
                    return false;
                }
            });
        },
        //     reserveAction() {
        //         let payloadData = {
        //         data: {
        //             status: this.payload.status,
        //             id: this.payload.data.id,
        //         },
        //         stateData: {
        //             index: this.payload.index,
        //             senderType: this.payload.senderType
        //         }
        //     }
            
        //     return store.dispatch('senderid/getSenderIDs', payloadData)
        //         .then(()=> {
                    
        //             this.$message({
        //                 type:'success',
        //                 message:' Approved'
        //             })
        //             this.loading = false
        //             this.success = message
        //             }).catch(() => {
                        
        //                 this.this.$message({
        //                     type:'error',
        //                     message:'There was an error'
        //                 })
        //         })
            
        // },
        hideModal(){
          $('#ReservedSenderIDs').modal('hide')
          this.error = ''
          this.success = ''
          this.loading = false
          this.formData = {
            reservedIDs: ''
          }    
        },
        openActionModal() {
            let element = this.$refs.actionModal.$el
            $(element).modal('show')
        },
        openReservedSenderIdModal() {
            $('#ReservedSenderIDs').modal('show')
        },
        editSenderId(data){
            this.payload = {
                type: 'edit',
                data: data,
                header:'Edit Sender Id'
            }
            this.formData.reservedIDs = data.row.reservedIDs
            this.openReservedIdModal()
        },
        deleteSenderId(data){
            this.payload = {
                message:'Are you sure you want to delete this Sender Id?',
                action: 'deleteSenderId',
                actionMessage: 'Delete',
                data: data,
                iconType: 'delete',
                index: data.$index
            }
            this.openActionModal()
        },
        openModal() {
            this.payload = {
                type : 'new',
                header: 'Create Reserved ID'
            }
            this.openReservedSenderIdModal()
        },
    },
    computed: {
        tableData() {
            return store.getters['admin/senderIDs/reservedSenderIds']
        },
        searchReservedIDs() {
            if (!this.search) {
                this.total = this.tableData.length;
                return this.tableData;
            }
            this.page = 1;
            return this.tableData
           
        },
        displayData() {
            return this.reservedIDs.filter(data => data.sender_id.toLowerCase().includes(this.search.toLowerCase()) );
        },
    },
    mounted() {
        this.loading = true
        store.dispatch('senderid/viewReservedSenderIDs')
        .then(
           (data) => {
             this.reservedIDs = data.data.data;
           }
        )  
        .finally( () => this.loading = false)  
    },
    
}
</script>
<style scoped>
#enter__account{
    color:#F7921C;
}
.actionBtn{
    outline:none;
    border:none;
    background: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
}
.create_notificaton{
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #FFFFFF;
    background: #F7921C;
    border-radius: 5px;
    outline:none;
    border:none;
    width:170px;
    height: 38px;
}
</style>